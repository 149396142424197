<template>
  <section class="sidebar" :class="{ collapsed: collapseSidebar, 'full-width': !collapseSidebar }">
    <div class="nav-container">
      <div class="sidebar__item" v-for="path in paths" :key="path.id">
        <a class="sidebar__link" :class='{ "active": determineActive(path.path) }' :href="path.path">
          <span class="sidebar__icon" :style="{ 'margin-right': collapseSidebar ? '0px' : '10px' }"><v-icon size="small"
              color="#023047">{{ path.icon }}</v-icon></span>
          <span class="sidebar__text" :style="{
            opacity: collapseSidebar ? '0' : '100',
            width: collapseSidebar ? '0px' : '100%',
          }">{{ path.text }}</span>
        </a>
      </div>
    </div>
    <div class="collapse-button-container">
      <v-btn variant="text" style="
              width: 100%;
              font-family: Manrope, sans-serif;
              letter-spacing: 0px;
              font-weight: 700;
              text-transform: capitalize;
              color: var(--accent-orange);
              padding: 0px;
              min-width: 3.375rem;
            " @click="collapse"><v-icon size="large">{{
              collapseSidebar
              ? "mdi-chevron-double-right"
              : "mdi-chevron-double-left"
            }}</v-icon></v-btn>
    </div>
  </section>
</template>

<script scoped>
export default {
  name: "SideBar",
  props: {
    paths: Array,
  },
  data() {
    return {
      collapseSidebar: null,
    };
  },
  created() {
    try {
      this.collapseSidebar = eval(localStorage.getItem("collapseSidebar"));
    } catch (DOMException) {
      this.collapseSidebar = false;
    }
  },
  methods: {
    collapse() {
      this.collapseSidebar = !this.collapseSidebar;
      localStorage.setItem("collapseSidebar", this.collapseSidebar);
    },
    determineActive(pathname) {
      if (pathname == window.location.pathname) {
        return true
      } else {
        return false
      }
    }
  },
};
</script>

<style scoped>
.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: var(--primary-color);
  transition: width cubic-bezier(0.4, 0, 0.2, 1) 0.25s;
}

.collapsed {
  width: 3.375em !important;
}

.full-width {
  width: 11em !important;
}

.nav-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.sidebar__item {
  margin-block: 0.5em;

  font-family: "Manrope", sans-serif !important;
  font-weight: 700 !important;
}

.sidebar__link {
  display: flex;
  align-items: center;

  text-decoration: none;
  color: var(--primary-color-lighten-2);
  border-radius: 0.5em;
}

.active {
  background-color: var(--primary-color-lighten-2);
  color: var(--primary-color);
}

.sidebar__link:hover {
  background-color: var(--primary-color-lighten-2);
  color: var(--primary-color);
}

.sidebar__icon {
  display: grid;
  place-items: center;
  padding: 7px;
  background-color: var(--primary-color-lighten-2);
  border-radius: 3em;
}

.sidebar__text {
  font-size: 0.9rem;
  transition: opacity ease-in-out 0.1s, width ease-in-out 0.2s;
}

.collapse-button-container {
  background-color: var(--accent-orange-lighten);
}
</style>
