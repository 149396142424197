<template>
  <v-app>
    <div class="text-center">
      <v-dialog v-model="cookiesBlocked" persistent class="cookies-dialog">
        <v-card class="cookies">
          <v-card-title class="cookies__title">
            <strong class="cookies__title-bold">Warning:</strong>
            Cookies are blocked or unsupported in your browser.
          </v-card-title>
          <v-card-text class="pt-0">
            <h3 class="mb-2 cookies__body-title">
              This site requires cookies to function properly.
            </h3>
            <p class="mb-4 cookies__description">
              In short, the cookies are used in various features of this
              application to make youe experience smoother and hassle free.
            </p>
            <v-btn class="mb-3 cookies__button" variant="tonal"
              >Learn How to Unblock Cookies</v-btn
            >
            <p class="cookies__post-text">
              Please review our
              <a href="#" class="cookies__post-text-link">Privacy Policy</a> to
              get more information on how your data is being used.
            </p>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <v-main v-if="differentLayout">
      <router-view :key="$router.fullPath" />
    </v-main>

    <v-main class="general-layout" v-else>
      <side-bar :paths="paths" />

      <div class="general-layout__contents">
        <global-header />

        <div class="general-layout__main">
          <router-view :key="$router.fullPath" />
          <global-footer />
        </div>
      </div>
    </v-main>
    <cookie-consent :consented="cookieConsented" />
  </v-app>
</template>

<script>
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalFooter from "@/components/GlobalFooter.vue";
import SideBar from "@/components/SideBar.vue";
import differentLayoutURL from "@/config/differentLayoutURL";
import CookieConsent from "@/components/Partials/CookieConsent.vue";
export default {
  name: "GharbahalVue",
  components: {
    "global-header": GlobalHeader,
    "global-footer": GlobalFooter,
    "side-bar": SideBar,
    "cookie-consent": CookieConsent,
  },
  data() {
    return {
      differentLayout: false,
      cookiesBlocked: false,
      cookieConsented: false,
      paths: [
        {
          id: 1,
          icon: "mdi-view-dashboard",
          text: "Dashboard",
          path: "/dashboard",
        },
        {
          id: 2,
          icon: "mdi-file-document-edit",
          text: "Agreements",
          path: "/agreements",
        },
        {
          id: 3,
          icon: "mdi-home-city",
          text: "Properties",
          path: "/properties",
        },
        {
          id: 4,
          icon: "mdi-account",
          text: "Tenant",
          path: "/tenants",
        },
        {
          id: 5,
          icon: "mdi-cog",
          text: "Settings",
          path: "/settings",
        },
      ],
    };
  },
  created() {
    this.differentLayout = differentLayoutURL.includes(
      window.location.pathname
    );
    try {
      this.cookieConsented =
        localStorage.cookieConsented === "true" ? true : false;
    } catch (DOMException) {
      this.cookiesBlocked = true;
      console.error(
        this.cookiesBlocked,
        "This site requires cookie permissions to function properly. Please review our Privacy Policy to get more inforation on how your data is being used."
      );
    }
  },
};
</script>


<style>
#app {
  margin: 0px;
  padding: 0px;
  font-family: "Inter", sans-serif;
}

.cookies-dialog {
  width: 600px !important;
}

.cookies {
  border-radius: 12px !important;
}

.cookies__title {
  font-family: "Manrope", sans-serif !important;
  font-size: 1rem !important;
  letter-spacing: 0px !important;
}
.cookies__title-bold {
  color: var(--red) !important;
}

.cookies__body-title {
  font-family: "Manrope", sans-serif;
  letter-spacing: 0px;
}

.cookies__description {
  font-size: 0.9rem !important;
  font-family: "Manrope", sans-serif !important;
  font-weight: 500 !important;
  letter-spacing: 0px !important;
}

.cookies__button {
  width: 100% !important;
  color: var(--primary-color) !important;
  font-family: "Manrope", sans-serif !important;
  font-weight: 700 !important;
  letter-spacing: 0px !important;
  text-transform: capitalize !important;
}

.cookies__post-text {
  font-size: 0.9rem !important;
  font-family: "Manrope", sans-serif !important;
  font-weight: 500 !important;
}

.cookies__post-text-link {
  color: #000 !important;
}

.general-layout {
  display: flex !important;
}

.general-layout__contents {
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9 !important;
}

.general-layout__main {
  height: 92vh !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;

  background-color: var(--gray-3) !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

@media only screen and (min-width: 1900px) {
  .general-layout__main {
    height: 94vh !important;
  }
}

/* ↓↓↓ TYPOGRAPHY ↓↓↓ */

/* to be used in inner text elements */
.inter {
  font-family: "Inter", sans-serif !important;
}

/* to be used in heading defining text elements */
.dm-sans {
  font-family: "DM Sans", sans-serif !important;
}

/* to be used in title defining text elements */
.kanit {
  font-family: "Kanit", sans-serif !important;
}

/* to be used in UI element labels */
.manrope {
  font-family: "Manrope", sans-serif !important;
}

/* landing page fonts */
.jost {
  font-family: "Jost", sans-serif !important;
}

.anton {
  font-family: "Anton", sans-serif !important;
}

/* ↓↓↓ FONT WEIGHT ↓↓↓ */
.regular {
  font-weight: 400 !important;
}

.medium {
  font-weight: 500 !important;
}

.semi-bold {
  font-weight: 600 !important;
}

.bold {
  font-weight: 700 !important;
}

.v-field__input {
  border: none !important;
}

/* ↓↓↓ selectors for different page views ↓↓↓ */

.login-container,
.register-container,
.support-container {
  height: 100vh !important;
  margin: 0px !important;
  padding: 0px !important;
}

.login-container .column--left,
.register-container .column--left,
.support-container .column--left {
  padding: 0px !important;
}

.login-container .column--right,
.register-container .column--right,
.support-container .column--right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
}

.login-form,
.support-form {
  margin: auto;
  margin-top: 200px;
  min-width: 600px;
  max-width: 700px;
}

@media only screen and (max-width: 1366px) {
  .support-form {
    margin-top: 50px;
  }
}

.login-form__inputs,
.support-form__inputs {
  margin-top: 64px;
}

.input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.login-form .login-form__title span,
.register-form .register-form__title span,
.support-form .support-form__title span {
  font-size: 2rem;
  font-weight: 700;
}

.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin-right: 5px;
  background-color: var(--input-field-bg);
  font-size: 20px;
  text-align: center;
  border-radius: 4px;
  border: none;
  outline: none;
}

.v-input__details {
  display: none !important;
}

.custom-input--datepicker {
  width: 100%;
  margin-top: 1rem;
  font-family: "Inter", sans-serif !important;
  padding-block: 8px;
  padding-left: 16px;
  padding-right: 6px;
  font-size: 1rem;
  border: 1px solid var(--gray-2);
  border-radius: 0.2em;
  outline: none;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.15s;
}

.custom-input--datepicker:hover {
  border-color: #000;
}

.page-title {
  margin-right: 1rem;
  font-family: "Kanit";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  color: var(--primary-color);
}

.required::after {
  content: " *";
  color: var(--red);
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

div.terminate > div > div > div {
  box-shadow: none !important;
}

.back-button {
  font-family: Manrope, sans-serif !important;
  color: var(--primary-color) !important;
  font-weight: 700 !important;
  letter-spacing: 0px !important;
  text-transform: capitalize !important;
}

.message {
  color: var(--red);
  line-height: 2rem;
}

.error-dialog {
  width: 31.5em;
}

.error__code {
  font-size: 3rem;
  text-align: center;
  color: var(--red);
}

.error__message {
  text-align: center;
  font-size: 1rem;
  color: var(--gray);
}

.btn--error_action {
  width: 100%;
  text-transform: capitalize;
  letter-spacing: 0px;
  color: #fff !important;
  background-color: var(--green);
}

.error-tooltip {
  background-color: var(--dark) !important;
  font-family: "DM Sans";
}

.card--no-data {
  border-radius: 0.75em;
}

.no-data__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-data__title-section .no-data__image-section {
  text-align: center;
}

.no-data__title {
  font-size: 2rem;
  text-transform: capitalize;
  color: var(--primary-color);
  margin-top: 2rem;
  margin-bottom: 2rem;
  /* fon */
}

.no-data__message-section {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 0.9rem;
  text-align: center;
}

.no-data__action-link {
  text-decoration: inherit;
  color: var(--primary-color);
  font-weight: 500;
}

.no-data__action-link:hover {
  text-decoration: underline;
}

/* Animattions */
.shake-bottom {
  -webkit-animation: shake-bottom 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
  animation: shake-bottom 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-6-2 16:23:59
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation shake-bottom
 * ----------------------------------------
 */
@-webkit-keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
@keyframes shake-bottom {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg);
  }
}
</style>
