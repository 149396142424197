import { createApp } from 'vue'
import App from './App.vue'
// ui plugins
import vuetify from './plugins/vuetify' // vuetify
import { loadFonts } from './plugins/webfontloader'
// router
import router from './router'
// otp input
import VOtpInput from 'vue3-otp-input'
// data table
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
loadFonts()

createApp(App).use(router).component('v-otp-input', VOtpInput).component('EasyDataTable', Vue3EasyDataTable)
  .use(vuetify)
  .mount('#app')
