<template>
  <header>
    <div class="branding">
      <h3 class="kanit">Gharbahal</h3>
    </div>
    <div class="links">
      <v-menu transition="slide-y-transition">
        <template v-slot:activator="{ props }">
          <a class="link" v-bind="props">Account</a>
        </template>
        <v-list density="compact"
          style="margin-top: -10px; border-radius: 0.4em; border: 1px solid var(--gray-4)" elevation="0">
          <v-list-item class="account-menu" active-color="primary">
            <v-list-item-title class="account-menu__text">Signed in as</v-list-item-title>
            <v-list-item-text>{{ name }}</v-list-item-text>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="account-menu" active-color="primary">
            <template v-slot:prepend>
              <v-icon size="small">mdi-account-outline</v-icon>
            </template>
            <v-list-item-title class="account-menu__text">My Account</v-list-item-title>
          </v-list-item>
          <v-list-item class="account-menu" active-color="primary" href="/properties">
            <template v-slot:prepend>
              <v-icon size="small">mdi-home-city-outline</v-icon>
            </template>
            <v-list-item-title class="account-menu__text">My Properties</v-list-item-title>
          </v-list-item>
          <v-list-item class="account-menu" active-color="primary" href="/agreements">
            <template v-slot:prepend>
              <v-icon size="small">mdi-file-document-edit-outline</v-icon>
            </template>
            <v-list-item-title class="account-menu__text">My Agreements</v-list-item-title>
          </v-list-item>
          <v-list-item class="account-menu" active-color="primary" href="/tenants">
            <template v-slot:prepend>
              <v-icon size="small">mdi-account-group-outline</v-icon>
            </template>
            <v-list-item-title class="account-menu__text">My Tenants</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="account-menu" active-color="primary">
            <template v-slot:prepend>
              <v-icon size="small">mdi-bell-badge-outline</v-icon>
            </template>
            <v-list-item-title class="account-menu__text">Notifications</v-list-item-title>
          </v-list-item>
          <v-list-item class="account-menu" active-color="primary">
            <template v-slot:prepend>
              <v-icon size="small">mdi-arrow-up-circle</v-icon>
            </template>
            <v-list-item-title class="account-menu__text">Upgrade</v-list-item-title>
          </v-list-item>
          <v-list-item class="account-menu" active-color="primary">
            <template v-slot:prepend>
              <v-icon size="small">mdi-help</v-icon>
            </template>
            <v-list-item-title class="account-menu__text">Help</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="account-menu" active-color="primary">
            <template v-slot:prepend>
              <v-icon size="small">mdi-logout-variant</v-icon>
            </template>
            <v-list-item-title class="account-menu__text" @click="signout">Sign Out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu transition="slide-y-transition">
        <template v-slot:activator="{ props }">
          <a class="link" v-bind="props">Help</a>
        </template>
        <v-list density="compact"
          style="margin-top: -10px; border-radius: 0.4em; border: 1px solid var(--gray-4)" elevation="0">
          <v-list-item class="account-menu" active-color="primary">
            <template v-slot:prepend>
              <v-icon size="small">mdi-book-information-variant</v-icon>
            </template>
            <v-list-item-title class="account-menu__text">View Documentation</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="account-menu" active-color="primary">
            <template v-slot:prepend>
              <v-icon size="small">mdi-face-agent</v-icon>
            </template>
            <v-list-item-title class="account-menu__text">Contact Support</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </header>
</template>

<script>
export default {
  name: "GlobalHeader",
  data(){
    return {
      name: ""
    }
  },
  created(){
    this.name = localStorage.getItem("name") || "Jane Doe"
  },
  methods: {
    signout(){
      localStorage.removeItem("Authentication")
      localStorage.removeItem("expires")
      localStorage.removeItem("memberId")
      window.location.href = "/"
    }
  }
};
</script>

<style scoped>
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}

@media only screen and (min-width: 1900px) {
  header {
    height: 6vh;
  }
}

.branding {
  display: flex;
  padding-inline: 2em;

  font-size: 1.6rem;
  font-weight: 600;
}

.branding h3 {
  font-size: inherit;
  font-weight: inherit;
}

.links {
  display: flex;
  height: 100%;
  margin-right: 8em;
}

.link {
  padding: 1em 3em;

  color: #000;
  text-decoration: none;
  font-family: Manrope, sans-serif;
  font-weight: 600;
  border-right: 2px solid var(--gray-2);
  cursor: pointer;
}

.link:first-child {
  border-left: 2px solid var(--gray-2);
}

.account-menu {
  cursor: pointer;
}

.account-menu:hover {
  background-color: var(--gray-4)
}

.account-menu__text {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 0.85rem;
}
</style>
