<template>
  <section v-if="!hide" class="cookie-consent__banner" :class="{ 'slide-out-bottom': playAnimation }">
    <p class="cookie-consent__text">
      <v-icon size="x-large" class="cookie-consent__icon mr-3"
        >mdi-cookie</v-icon
      >
      <span class="cookie-consent__message mr-2"
        >This website uses cookies to ensure you get the best experience on our
        website.</span
      ><a href="#" class="cookie-consent__link">Learn More</a>
    </p>
    <v-btn variant="flat" @click="gotIt" class="cookie-consent-button">Got It</v-btn>
  </section>
</template>

<script>
export default {
  name: "CookieConsent",
  data() {
    return {
      hide: false,
      playAnimation: false,
    };
  },
  props: {
    consented: {
      type: Boolean,
      default: false,
    },
  },
  created(){
    this.hide = this.consented
  },
  methods:{
    gotIt(){
      this.playAnimation = true;
      
      setTimeout(()=>{
        this.hide = true;
        localStorage.setItem("cookieConsented", this.hide)
      }, 1001)
    }
  }
};
</script>

<style scoped>
.cookie-consent-button{
  border-radius: 0px !important;
}
.cookie-consent__banner {
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1em;
  background-color: #000;
  z-index: 99;
}

.cookie-consent__icon {
  color: var(--accent-orange);
}

.cookie-consent__text {
  display: flex;
  align-items: center;
}
.cookie-consent__message {
  color: #fff;
  font-family: 'Manrope', sans-serif;
}

.cookie-consent__link {
  color: var(--secondary-color-lighten-1);
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  text-decoration: none;
}

/* Slide Out Animation */

.slide-out-bottom {
  -webkit-animation: slide-out-bottom 1s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    backwards;
  animation: slide-out-bottom 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) backwards;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-1-26 11:49:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
@keyframes slide-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
}
</style>
