import { createRouter, createWebHistory } from 'vue-router';
import moment from 'moment';

const routes = [
    /*
     * pre authentication
     */
    {
        path: '/',
        name: 'LandingPage',
        component: () =>
            import(
                /* webpackChunkName: "landingPage" */ '@/views/landingPage/index.vue'
            ),
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import(/* webpackChunkName: "login" */ '@/views/login/Login.vue'),
    },
    {
        path: '/register',
        name: 'Register',
        component: () =>
            import(
                /* webpackChunkName: "register" */ '@/views/register/Register.vue'
            ),
    },
    {
        path: '/plans',
        name: 'Plans',
        component: () =>
            import(
                /* webpackChunkName: "plans" */ '@/views/plans/ChoosePlan.vue'
            ),
    },
    // ? support tickets
    {
        path: '/support-ticket',
        name: 'SupportTicket',
        component: () =>
            import(
                /* webpackChunkName: "supportTicket" */ '@/views/supportTicket/supportTicket.vue'
            ),
    },
    /*
     * post authentication
     */
    // ? dashboard
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () =>
            import(
                /* webpackChunkName: "dashboard" */ '@/views/dashboard/Dashboard.vue'
            ),
    },
    // ? agreements
    {
        path: '/agreements',
        name: 'AgreementsIndex',
        component: () =>
            import(
                /* webpackChunkName: "agreementsIndex" */ '@/views/agreements/index.vue'
            ),
    },
    {
        path: '/agreements/create',
        name: 'AgreementsCreate',
        component: () =>
            import(
                /* webpackChunkName: "agreementCreate" */ '@/views/agreements/create.vue'
            ),
    },
    {
        path: '/agreements/history',
        name: 'AgreementsHistory',
        component: () =>
            import(
                /* webpackChunkName: "agreementsHistory" */ '@/views/agreements/history.vue'
            ),
    },
    {
        path: '/agreements/:id',
        name: 'AgreementShow',
        component: () =>
            import(
                /* webpackChunkName: "agreementShow" */ '@/views/agreements/show.vue'
            ),
    },
    // ? properties
    {
        path: '/properties',
        name: 'PropertiesIndex',
        component: () =>
            import(
                /* webpackChunkName: "propertiesIndex" */ '@/views/properties/index.vue'
            ),
    },
    {
        path: '/properties/create',
        name: 'PropertiesCreate',
        component: () =>
            import(
                /* webpackChunkName: "propertiesCreate" */ '@/views/properties/create.vue'
            ),
    },
    {
        path: '/properties/edit/:id',
        name: 'PropertiesEdit',
        component: () =>
            import(
                /* webpackChunkName: "propertiesEdit" */ '@/views/properties/edit.vue'
            ),
    },
    {
        path: '/properties/:id',
        name: 'PropertiesShow',
        component: () =>
            import(
                /* webpackChunkName: "propertiesShow" */ '@/views/properties/show.vue'
            ),
    },
    // ? tenants
    {
        path: '/tenants',
        name: 'TenantsIndex',
        component: () =>
            import(
                /* webpackChunkName: "tenantsIndex" */ '@/views/tenants/index.vue'
            ),
    },
    {
        path: '/tenants/:id',
        name: 'TenantsShow',
        component: () =>
            import(
                /* webpackChunkName: "tenantShow" */ '@/views/tenants/show.vue'
            ),
    },
    // ? settings
    {
        path: '/settings',
        name: 'Settings',
        component: () =>
            import(
                /* webpackChunkName: "settingsIndex" */ '@/views/settings/index.vue'
            ),
    },
    // ! 404 page
    {
        path: '/404',
        name: '404NotFound',
        component: () =>
            import(/* webpackChunkName: "404NotFound" */ '@/views/404/404.vue'),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
    if (!to.matched.length) {
        window.location.href = '/404';
    } else if (
        !['/', '/login', '/register', '/plans', '/support-ticket'].includes(
            to.path,
        ) &&
        !localStorage.getItem('Authentication') &&
        !localStorage.getItem('expires')
    ) {
        window.location.href = '/login';
    } else if (
        !['/', '/login', '/register', '/plans', '/support-ticket'].includes(
            to.path,
        ) &&
        moment.duration(moment().diff(moment(localStorage.expires))) > 0
    ) {
        window.location.href = '/login';
    } else {
        next();
    }
});

export default router;
