<template>
  <footer class="manrope">
    Gharbahal © 2023 Beam Lab Pvt. Ltd. |&nbsp;<router-link
      class="link semi-bold"
      to="/terms-of-use"
      >Terms of Use</router-link
    >&nbsp;&amp;&nbsp;<router-link to="/privacy-policy" class="link semi-bold"
      >Privacy Policy</router-link
    >
  </footer>
</template>

<script>
export default {
  name: "GlobalFooter",
};
</script>

<style scoped>
footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 60px;
  margin-top: 3rem;
  text-align: center;
}

@media only screen and (max-width: 1366px) {
  footer {
    margin-top: 0.5rem;
  }
}

footer .link {
  text-decoration: none;
  color: #000;
}
</style>
