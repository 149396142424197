const differentLayoutURL = [
  '/',
  '/login',
  '/register',
  '/plans',
  '/support-ticket',
  '/404'
]

export default differentLayoutURL;
